import React from "react";
import API from "../../api";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Loading from "../Loading";

const schema = yup.object().shape({
  username: yup
    .string()
    .email("Inserisci una mail valida")
    .max(255)
    .required("Campo email obbligatorio"),
});

class RecuperaPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      infoMessage: "",
      error: "",
    };
  }

  registrationFunction(values) {
    API.post("api/auth/resetPassword?email=" + values.username)
      .then((response) => {
        this.setState({
          error: "",
          infoMessage: "Controlla la tua email",
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          infoMessage: "",
          error:
            "Impossibile effettuare il reset della password. Controlla che la mail inserita sia corretta oppure contattaci",
          loading: false,
        });
      });
  }

  getButtons() {
    if (this.state.error) {
      return (
        <>
          <div className="error-message">{this.state.error}</div>
          <Button variant="primary" type="submit">
            Recupera password
          </Button>
        </>
      );
    } else {
      return (
        <>
        <Button variant="primary" type="submit">
          Recupera password
        </Button>
        <div>{this.state.infoMessage}</div>
      </>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      return (
        <Formik
          validationSchema={schema}
          onSubmit={(values, actions) => {
            this.setState({ isLoading: true });
            actions.setSubmitting(false);
            this.registrationFunction(values);
          }}
          initialValues={{
            username: "",
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="form-style">
              <Form.Group controlId="validationFormikUsername">
                <Form.Label>Email</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    aria-describedby="inputGroupPrepend"
                    name="username"
                    value={values.username}
                    onChange={handleChange}
                    isInvalid={!!errors.username}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.username}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              {this.getButtons()}
            </Form>
          )}
        </Formik>
      );
    }
  }
}

export default RecuperaPasswordForm;
