import React from "react";
import { Card } from "react-bootstrap";
import "./Girone.css";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import API from "../../api";
import Loading from "../Loading";

class Girone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ranking: [],
      current_page: 1,
      error: null,
      hasMore: true,
      allTeam: [],
      tableHead: ["", "PT", "GF", "GS", "V", "P", "N"],
      tableTitle: [],
      tableData: [],
    };
  }

  componentDidMount() {
    this.getAllTeam();
  }

  getAllTeam() {
    this.setState({
      loading: true,
    });
    API.get("team/all")
      .then((response) => {
        this.setState({
          allTeam: response.data,
          loading: false,
        });
        this.setRanking();
      })
      .catch((error) => {
        this.setState({
          error: "Error retrieving data",
          loading: false,
        });
      });
  }

  setRanking() {
    const { groupId } = this.props;
    this.setState({
      loading: true,
    });
    API.get("group/rankings?groupId=" + groupId)
      .then((response) => {
        this.setState({
          ranking: response.data,
          loading: false,
        });
        this.setTeamNameAndData();
      })
      .catch((error) => {
        this.setState({
          error: "Error retrieving data",
          loading: false,
        });
      });
  }
  
  setTeamNameAndData() {
    this.setState({
      loading: true,
    });
    var teamNames = [];
    const dataTabel = [];
    this.state.ranking.forEach((ranking) => {
      const row = [];
      let teamName = "";
      this.state.allTeam.forEach((team) => {
        if (team.ranking !== null && team.ranking !== undefined) {
          if (team.ranking.id === ranking.id) {
            teamName = team.name;
          }
        }
      });
      row.push(teamName);
      row.push(ranking.points);
      row.push(ranking.goalsMade);
      row.push(ranking.goalsConceded);
      row.push(ranking.won);
      row.push(ranking.lost);
      row.push(ranking.drawn);
      dataTabel.push(row);
    });

    this.setState({
      tableTitle: teamNames,
      tableData: dataTabel,
      loading: false,
    });
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      const group = this.props.group;
      const state = this.state;
      if (group) {
        return (
          <Card>
            <Card.Body>
              <Card.Header>
                <h5 className="girone__item__title">{group.name}</h5>
              </Card.Header>
              <Card.Text>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            position: "sticky",
                            left: 0,
                            backgroundColor: "white",
                            width: "40%",
                          }}
                        ></TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          PT
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          GF
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          GS
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>V</TableCell>
                        <TableCell style={{ textAlign: "center" }}>P</TableCell>
                        <TableCell style={{ textAlign: "center" }}>N</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {state.tableData.map((row) => (
                        <TableRow key={row[0]}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: "white",
                              width: "40%",
                              fontSize: "15px",
                            }}
                          >
                            {row[0]}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {row[1]}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {row[2]}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {row[3]}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {row[4]}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {row[5]}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {row[6]}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card.Text>
            </Card.Body>
          </Card>
        );
      } else {
        return (
          <Card>
            <Card.Body>
              <Card.Header className="card-header-empty">
                Nessun Girone disponibile
              </Card.Header>
            </Card.Body>
          </Card>
        );
      }
    }
  }
}

export default Girone;
