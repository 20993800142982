import React from "react";
import CalendarMatch from "./CalendarMatch";
import "./CalendarMatches.css";
import { Container, Card, Row, Col } from "react-bootstrap";
import API from "../../api";
import Moment from "moment";
import Loading from "../Loading";
import CardDefault from "../Card/CardDefault";
import CardTitle from "../Card/CardTitle";

class CalendarMatches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matches: [],
      mapGroupDay: new Map(),
      loading: false,
    };
  }

  componentDidMount() {
    this.getCalendar();
  }

  getCalendar() {
    this.setState({
      loading: true,
    });
    API.get("match/all/statistics")
      .then((response) => {
        this.populateGroupMap(response.data);
      })
      .catch((error) => {
        this.setState({
          error: "Error retrieving data",
          loading: false,
        });
      });
  }

  populateGroupMap(matches) {
    this.setState({
      loading: true,
    });
    const map = new Map();
    matches.forEach((match) => {
      if (
        match.matchType === this.props.type1 ||
        match.matchType === this.props.type2
      ) {
        var date = Moment(match.date).format("DD-MM-YYYY");
        if(date.includes("29")){
          var fixdate = Moment(match.date).subtract(1, 'd');
          date = fixdate.format("DD-MM-YYYY");
        }
        if (map.has(date)) {
          map.get(date).push(match);
        } else {
          map.set(date, [match]);
        }
      }
    });

    this.setState({
      mapGroupDay: map,
      loading: false,
    });
  }

  goToMatchDetail(match) {
    if (match.homeTeam === undefined || match.guestTeam === null) {
      return null;
    } else {
      this.props.navigation.navigate("MatchDetail", {
        matchId: match.matchId,
      });
    }
  }

  render() {
    const title = this.props.title;
    if (this.state.loading) {
      return <Loading />;
    } else {
      var calendarMap = [];
      var index = 1;

      if (this.state.mapGroupDay.size === 0) {
        calendarMap.push(
          <Col md="6" key={index}>
            <Card>
              <Card.Body>
                <Card.Header className="card-header-empty">
                  <Card.Text>Nessuna gara disponibile</Card.Text>
                </Card.Header>
              </Card.Body>
            </Card>
          </Col>
        );
      } else {
        this.state.mapGroupDay.forEach((values, keys) => {
          var matches = [];
          var type = "";
          var indexMatch = 1;
          // eslint-disable-next-line array-callback-return
          values.map((item, key) => {
            type = item.matchType.toString();
            matches.push(
              <CalendarMatch
                key={item.id}
                match={item}
                isLast={indexMatch === values.length}
              />
            );
            indexMatch = indexMatch + 1;
          });
          var title = "";
          if (type.includes("Semifinale")) {
            title = "Semifinale ";
          } else {
            if (type.includes("Finale")) {
              title = "Finale ";
            } else {
              title = index + "° Giornata ";
            }
          }
          var data = keys;
          var matchInfo = title + data;
          calendarMap.push(
            <Col md="4" key={index}>
              <CardDefault
                src="images/2.jpg"
                text={matches}
                label={matchInfo}
                path="/services"
              />
            </Col>
          );
          index = index + 1;
        });
      }
    }

    return (
      <div className="body-default">
        <Container>
          <CardTitle text={title} />
        </Container>
        <Container fluid>
          <Row className="justify-content-md-center">{calendarMap}</Row>
        </Container>
      </div>
    );
  }
}

export default CalendarMatches;
