import CalendarMatches from '../Calendar/CalendarMatches'

function Calendar() {
    return (
      <div className='pageStyle' style={{ paddingBottom: "30px "}}>
        <CalendarMatches title='FASE A GIRONI' type1='Gruppo' type2=''/> 
        <CalendarMatches title='FASE FINALE EURO CUP' type1='Semifinale Euro Cup' type2='Finale Euro Cup'/>
        <CalendarMatches title='FASE FINALE ROVARE CUP' type1='Semifinale Rovarè Cup' type2='Finale Rovarè Cup'/>
        </div>
    );
  }
  
  export default Calendar