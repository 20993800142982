import React from "react";
import { Card, Row, Container, Col } from "react-bootstrap";
import TeamCustomerHelper from "../../helper/TeamCustomerHelper";
import CardTitle from "../Card/CardTitle";
import MatchesTeam from "../pages/MatchesTeam";

function Team() {
  return (
    <>
      <Container>
        <div className="page-title">
          <h2>La tua squadra</h2>
        </div>
        <Row className="justify-content-md-center">
          <Col md="12">
            <Card>
              <Card.Body>
                <Card.Text style={{paddingTop: "20px"}}className="justify-content-md-center">
                <MatchesTeam teamId={32}/>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="justify-content-md-center statistic">
          <Col md="12">
            <Card>
              <Card.Body>
                <Card.Text className="justify-content-md-center statistic">
                  <TeamCustomerHelper />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Team;
