import React from "react";
import Loading from "./Loading";
import axios from "../api";
import { Container, Row, Col } from "react-bootstrap";
import PlayersTeamDetail from "./Team/PlayersTeamDetail";

class TeamDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableHead: ["Giocatore", "", ""],
      tableTitle: [],
      tableData: [],
      tableDataDirigenti: [],
      players: [],
      dirigenti: [],
      teamName: "",
      team: [],
    };
  }

  componentDidMount() {
    this.getTeam();
  }

  getTeam() {
    this.setState({
      loading: true,
    });
    axios
      .get("/team?id=" + this.props.id)
      .then((response) => {
        this.setState({
          team: response.data,
          players: response.data.players,
          dirigenti: response.data.leaders,
          teamName: response.data.name
        });
        this.setPlayers();
        this.setDirigenti();
      })
      .catch((error) => {
        this.setState({
          error: "Qualcosa è andato storto :) Contattaci se hai problemi",
          loading: false,
        });
      });
  }

  setPlayers() {
    const dataTabel = [];
    this.state.players.forEach((player) => {
      const row = [];
      row.push(player.name + " " + player.surname);
      row.push(player.id);
      row.push("");
      dataTabel.push(row);
    });
    this.setState({
      error: "",
      loading: false,
      tableData: dataTabel,
    });
  }

  setDirigenti() {
    const dataTabel = [];
    this.state.dirigenti.forEach((dirigente) => {
      const row = [];
      row.push(dirigente.name + " " + dirigente.surname);
      row.push(dirigente.id);
      row.push("");
      dataTabel.push(row);
    });
    this.setState({
      error: "",
      loading: false,
      tableDataDirigenti: dataTabel,
    });
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      return (
        <Container>
          <h2>{this.state.teamName}</h2>
          <Row className="justify-content-md-center statistic">
            <Col >
              <PlayersTeamDetail players={this.state.tableData} team={this.state.team}/>
            </Col>
          </Row>
        </Container>
      );
    }
  }
}

export default TeamDetailPage;
