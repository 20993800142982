import React from "react";
import Video from "./Video";
import "./Videos.css";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import CardTitle from "./Card/CardTitle";

const onHandleClick = () => {
    window.open("https://www.youtube.com/channel/UC4kL40EfJsdADJEpzC6E_OA/videos", "_blank");
}

function Videos(props) {
  // return <Video videoId="VrJhWsyWVoI" />;
  return (
    <Container>
      <CardTitle text='HIGHLIGHTS' />
      <Row className="justify-content-md-center">
        <Col sm={6}>
          <Card style={{ background: "transparent", border: "0" }}>
            <Card.Body>
              <Video videoId="VrJhWsyWVoI" />
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6}>
          <Card style={{ background: "transparent", border: "0" }}>
            <Card.Body>
              <Video videoId="VrJhWsyWVoI" />
            </Card.Body>
          </Card>
        </Col>
        <br />
        <Col md="auto" className="align-self-center">
          <Button variant="danger" size="sm" className="button" onClick={onHandleClick}>Vedi gli altri video su Youtube
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default Videos;
