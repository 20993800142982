import React from "react";
import "./CalendarMatch.css";
import { Container, Row, Col, Table } from "react-bootstrap";
import Moment from "moment";
import BlinkText from "../BlinkText";
import { Link } from "react-router-dom";

class CalendarMatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
  }

  goToMatchDetail(match) {
    if (match.homeTeam === undefined || match.guestTeam === null) {
      return null;
    } else {
      this.props.browserHistory.push("/home");
    }
  }

  render() {
    Moment.locale("it");
    const { match, isLast } = this.props;
    const matchInput = match;
    var cssClass = "";
    if (!isLast) {
      cssClass = "border-card";
    }
    if (
      matchInput !== null &&
      matchInput !== undefined &&
      matchInput.matchId !== undefined
    ) {
      var date = matchInput.date;
      var homeTeamName = matchInput.homeTeam;
      var guestTeamName = matchInput.guestTeam;
      if (homeTeamName === undefined || homeTeamName === null) {
        homeTeamName = "Da definire";
        guestTeamName = "Da definire";
      }
      if(homeTeamName === 'Da definire' || guestTeamName === 'Da definire') {
        return (
          <Container key={matchInput.matchId} className={cssClass}>
              <Row className="justify-content-md-center">
                <Col md="9" xs>
                  <Table responsive borderless size="sm">
                    <tbody>
                      <tr>
                        <td className="team">Da definire</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col xs="3" className="hour align-self-center">
                  <i className="far fa-clock"></i>
                  {Moment(date).subtract(2, "h").format("HH:mm")}
                </Col>
              </Row>
          </Container>
        );
      } else if (matchInput.status !== "Finita" && matchInput.status !== "InCorso") {
        return (
          <Container key={matchInput.matchId} className={cssClass}>
            <Link
              to={{
                pathname: "/match",
                state: { matchId: matchInput.matchId },
              }}
            >
              <Row className="justify-content-md-center">
                <Col md="9" xs>
                  <Table responsive borderless size="sm">
                    <tbody>
                      <tr>
                        <td className="team">{homeTeamName}</td>
                      </tr>
                      <tr>
                        <td className="team">{guestTeamName}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col xs="3" className="hour align-self-center">
                  <i className="far fa-clock"></i>
                  {Moment(date).subtract(2, "h").format("HH:mm")}
                </Col>
              </Row>
            </Link>
          </Container>
        );
      } else {
        if (matchInput.status === "Finita") {
          var goalHome = matchInput.homeResult;
          var goalGuest = matchInput.guestResult;
          if (matchInput.penalty === true) {
            goalHome = goalHome + " (" + matchInput.penaltyHomeGol + ")";
            goalGuest = goalGuest + " (" + matchInput.penaltyGuestGol + ")";
          }
          if (
            parseInt(matchInput.homeResult) +
              parseInt(matchInput.penaltyHomeGol) >
            parseInt(matchInput.guestResult) +
              parseInt(matchInput.penaltyGuestGol)
          ) {
            goalHome = (
              <p className="p-result">
                {goalHome}
                <i className="fas fa-caret-left win-icon"></i>
              </p>
            );
          }
          if (
            parseInt(matchInput.homeResult) +
              parseInt(matchInput.penaltyHomeGol) <
            parseInt(matchInput.guestResult) +
              parseInt(matchInput.penaltyGuestGol)
          ) {
            goalGuest = (
              <p className="p-result">
                {goalGuest}
                <i className="fas fa-caret-left win-icon"></i>
              </p>
            );
          }
          return (
            <Container key={matchInput.matchId} className={cssClass}>
              <Link
                to={{
                  pathname: "/match",
                  state: { matchId: matchInput.matchId },
                }}
              >
                <Row className="justify-content-md-center">
                  <Col md="9" xs>
                    <Table responsive borderless size="sm">
                      <tbody>
                        <tr>
                          <td className="team">{homeTeamName}</td>
                        </tr>
                        <tr>
                          <td className="team">{guestTeamName}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col xs="3" className="hour align-self-center">
                    <Table responsive borderless size="sm">
                      <tbody>
                        <tr>
                          <td className="result">{goalHome}</td>
                        </tr>
                        <tr>
                          <td className="result">{goalGuest}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Link>
            </Container>
          );
        } else {
          return (
            <Container key={matchInput.matchId} className={cssClass}>
              <Link
                to={{
                  pathname: "/match",
                  state: { matchId: matchInput.matchId },
                }}
              >
                <Row className="justify-content-md-center">
                  <Col md="8" xs>
                    <Table responsive borderless size="sm">
                      <tbody>
                        <tr>
                          <td className="team">{homeTeamName}</td>
                        </tr>
                        <tr>
                          <td className="team">{guestTeamName}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col xs="3" className="hour align-self-center">
                    <Table responsive borderless size="sm">
                      <tbody>
                        <tr>
                          <td className="result">
                            <BlinkText textData={matchInput.homeResult} />
                          </td>
                        </tr>
                        <tr>
                          <td className="result">
                            <BlinkText textData={matchInput.guestResult} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Link>
            </Container>
          );
        }
      }
    } else {
      return <Container className="border-card"></Container>;
    }
  }
}

export default CalendarMatch;
