import React from "react";
import "../../App.css";
import HeroSection from "../HomePage/HeroSection";
import Cards from "../HomePage/Cards";



function Home() {
  return (
    <>
    <div style={{background: "white"}}> <HeroSection /></div>
      <Cards />
    </>
  );
}

export default Home