import React from "react";
import Loading from "../Loading";
import axios from "../../api";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

class TeamsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      teams: [],
      tableData: [],
    };
  }

  componentDidMount() {
    this.getTeam();
  }

  getTeam() {
    this.setState({
      loading: true,
    });
    axios
      .get("team/all")
      .then((response) => {
        this.setState({
          teams: response.data,
          loading: false,
        });
        this.setTableTeams();
      })
      .catch((error) => {
        this.setState({
          error: "Error retrieving data",
          loading: false,
        });
      });
  }

  setTableTeams() {
    const dataTabel = [];
    this.state.teams.forEach((group) => {
      const row = [];
      row.push(group.name);
      row.push(group.id);
      dataTabel.push(row);
    });
    this.setState({
      error: "",
      loading: false,
      tableData: dataTabel,
    });
  }

  edit(id) {
    window.location = "/teamDetail?id=" + id;
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      if (this.state.teams.length > 0) {
        return (
          <Container>
            <div className="page-title">
              <h2>Squadre</h2>
            </div>
            <Row className="justify-content-md-center statistic">
              <Col md="6">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      
                      <TableRow>
                        <TableCell style={{ width: "90%" }}></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.tableData.map((row) => (
                        <TableRow key={row[0]}>
                          <TableCell style={{ width: "90%" }}>
                            {row[0]}
                          </TableCell>
                          <TableCell>
                            <Button
                              className="edit"
                              onClick={this.edit.bind(this, row[1])}
                            >
                              <i class="fas fa-info edit"></i>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Col>
            </Row>
          </Container>
        );
      } else {
        return (
          <Container>
            <Row className="justify-content-md-center statistic">
              <Col md="12">Nessuna squadra presente</Col>
            </Row>
          </Container>
        );
      }
    }
  }
}

export default TeamsTable;
