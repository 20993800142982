import React from "react";
import { Container, Card, ProgressBar, Row, Col, Image } from "react-bootstrap";
import Loading from "./Loading";
import API from "../api";
import BlinkText from "./BlinkText";
import "./MatchDetail.css";
import Moment from "react-moment";
import { keys } from "@material-ui/core/styles/createBreakpoints";

class MatchDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      matchId: [],
      match: [],
      homePlayers: [],
      guestPlayers: [],
      homeGoals: [],
      guestGoals: [],
      onShotValue: [],
      outShotValue: [],
      cornerValue: [],
      yellowValue: [],
      redValue: [],
    };
  }

  loadData() {
    this.getMatch(this.props.matchId);
  }
  componentDidMount() {
    this.loadData();
  }

  getMatch(id) {
    this.setState({
      loading: true,
    });
    API.get("match/statistics?id=" + id)
      .then((response) => {
        this.setState({
          loading: false,
          match: response.data,
          homePlayers: this.getPlayers(
            response.data.homePlayers,
            true,
            response.data
          ),
          guestPlayers: this.getPlayers(
            response.data.guestPlayers,
            false,
            response.data
          ),
          homeGoals: this.getGoals(
            response.data.homePlayers,
            response.data.homeGoals
          ),
          guestGoals: this.getGoals(
            response.data.guestPlayers,
            response.data.guestGoals
          ),
          onShotValue: this.getProgressBar(
            response.data.homeShotsOnGol,
            response.data.guestShotsOnGol,
            false,
            false,
            "Tiri in porta"
          ),
          outShotValue: this.getProgressBar(
            response.data.homeShotsOutGol,
            response.data.guestShotsOutGol,
            false,
            false,
            "Tiri fuori"
          ),
          cornerValue: this.getProgressBar(
            response.data.homeCorners,
            response.data.guestCorners,
            false,
            false,
            "Corner"
          ),
          yellowValue: this.getProgressBar(
            response.data.homeCards,
            response.data.guestCards,
            true,
            true,
            "Ammonizioni"
          ),
          redValue: this.getProgressBar(
            response.data.homeCards,
            response.data.guestCards,
            true,
            false,
            "Espulsioni"
          ),
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      });
  }

  getGoals(inputPlayers, inputGoals) {
    var goals = [];
    const map1 = new Map();
    inputGoals.sort(function (a, b) {
      return a.minute - b.minute;
    });
    inputGoals.forEach((goal, keys) => {
      inputPlayers.forEach((player, keys) => {
        var minute = "";
        player.goals.forEach((goalPlayer, keys) => {
          if (goalPlayer.id === goal.id) {
            minute = goal.minute;
          }
        });
        if (minute !== "") {
          var name = player.name;
          var minutesSorted = minute + "' ";
          name = name.charAt(0) + ".";
          if (
            map1.get(name + player.surname) !== undefined
          ) {
            minutesSorted = map1.get(name + player.surname) + minutesSorted;
            map1.set(name + player.surname, minutesSorted);
          } else {
            map1.set(name + player.surname, minutesSorted);
          }
        }
      });
    });
    console.log(map1);
    map1.forEach((value, key) => {
      goals.push(
        <div key={key}>
          {key} {value}
        </div>
      );
    });

    return goals;
  }

  getProgressBar(home, guest, iscard, isYellow, title) {
    var homeValue = home;
    var guestValue = guest;
    var valueProgess = 0;
    var cssClass;
    if (iscard === true) {
      homeValue = 0;
      guestValue = 0;
      home.forEach((card, keys) => {
        if (isYellow === true) {
          if (card.type === "Ammonizione") {
            homeValue = homeValue + 1;
          }
        } else {
          if (card.type === "Espulsione") {
            homeValue = homeValue + 1;
          }
        }
      });
      guest.forEach((card, keys) => {
        if (isYellow === true) {
          if (card.type === "Ammonizione") {
            guestValue = guestValue + 1;
          }
        } else {
          if (card.type === "Espulsione") {
            guestValue = guestValue + 1;
          }
        }
      });
    }
    if (homeValue === 0 && guestValue === 0) {
      valueProgess = 0;
      cssClass = "progress-empty";
    } else {
      valueProgess = (100 * homeValue) / (homeValue + guestValue);
    }
    var progressBar = [];
    progressBar.push(
      <Container key={title} fluid>
        <Row className="justify-content-md-center statistic">
          <Col md="12">{title}</Col>
          <Container key={title}>
            <Row className="justify-content-md-center statistic">
              <Col md="2" xs={2}>
                {homeValue}
              </Col>
              <Col md="8" xs={8}>
                <ProgressBar now={valueProgess} className={cssClass} />
              </Col>
              <Col md="2" xs={2}>
                {guestValue}
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    );
    return progressBar;
  }

  getMatchDetail(match) {
    var date = <Moment subtract={{ hours: 2 }}>{match.date}</Moment>;
    if (match.matchType === "Gruppo") {
      return (
        <div>
          <div>{date}</div>
          {match.group.name}
        </div>
      );
    } else {
      return (
        <div>
          <div>{date}</div>
          {match.matchType}
        </div>
      );
    }
  }

  getDateOrResult(match) {
    var value = [];
    if (match.status === "Finita" || match.status !== "InCorso") {
      if (match.penalty === true) {
        let display = match.homeResult + "-" + match.guestResult;
        let displayPenalty =
          "(" +
          match.penaltyHomeGol +
          "-" +
          match.penaltyGuestGol +
          ")" +
          " dcr";
        value.push(
          <div key={match.id}>
            <div>{display}</div>
            <div style={{ marginTop: "-13px" }}>{displayPenalty}</div>
          </div>
        );
      } else {
        let display = match.homeResult + "-" + match.guestResult;
        value.push(<div key={match.id}>{display}</div>);
      }
    } else {
      let display = match.homeResult + "-" + match.guestResult;
      value.push(<BlinkText textData={display} />);
    }
    return value;
  }

  getPlayers(inputPlayers, isHome, match) {
    var players = [];
    inputPlayers.forEach((player, keys) => {
      var name = player.name;
      var goals = [];
      var cards = [];
      var bests = [];
      name = name.charAt(0) + ".";
      player.goals.forEach((goal, keys) => {
        if (isHome) {
          match.homeGoals.forEach((homeGoal, key) => {
            if (homeGoal.id === goal.id) {
              goals.push(
                <Image
                  className="icon2"
                  key={homeGoal.id}
                  src="images/gol.png"
                />
              );
            }
          });
        } else {
          match.guestGoals.forEach((guestGol, key) => {
            if (guestGol.id === goal.id) {
              goals.push(
                <Image
                  className="icon2"
                  key={guestGol.id}
                  src="images/gol.png"
                />
              );
            }
          });
        }
      });
      player.best.forEach((best, keys) => {
        if (isHome) {
          match.homeBest.forEach((homeBest, key) => {
            if (homeBest.id === best.id) {
              bests.push(
                <Image
                  className="icon2"
                  key={homeBest.id}
                  src="images/star.png"
                />
              );
            }
          });
        } else {
          match.guestBest.forEach((guestBest, key) => {
            if (guestBest.id === best.id) {
              bests.push(
                <Image
                  className="icon2"
                  key={guestBest.id}
                  src="images/star.png"
                />
              );
            }
          });
        }
      });
      player.cards.forEach((card, keys) => {
        if (isHome) {
          match.homeCards.forEach((homeCard, key) => {
            if (homeCard.id === card.id) {
              if (card.type === "Ammonizione") {
                cards.push(
                  <Image
                    className="icon2"
                    key={homeCard.id}
                    src="images/yellow.png"
                  />
                );
              } else {
                cards.push(
                  <Image
                    className="icon2"
                    key={homeCard.id}
                    src="images/red.png"
                  />
                );
              }
            }
          });
        } else {
          match.guestCards.forEach((guestCard, key) => {
            if (guestCard.id === card.id) {
              if (card.type === "Ammonizione") {
                cards.push(
                  <Image
                    key={guestCard.id}
                    className="icon2"
                    src="images/yellow.png"
                  />
                );
              } else {
                cards.push(
                  <Image
                    key={guestCard.id}
                    className="icon2"
                    src="images/red.png"
                  />
                );
              }
            }
          });
        }
      });
      players.push(
        <div key={player.id} className="players">
          {name} {player.surname} {goals} {cards} {bests}
        </div>
      );
    });
    return players;
  }

  render() {
    const match = this.state.match;
    if (this.state.loading) {
      return <Loading />;
    } else {
      return (
        <div className="body-match">
          <Container fluid style={{ paddingTop: "30px" }}>
            <Row className="justify-content-md-center">
              <Col md="10" sm="12">
                <Card>
                  <Card.Body>
                    <Card.Text className="card-text-result">
                      <Container fluid>
                        <Row className="justify-content-md-center">
                          <Col className="team-statistiche" md="5" xs="5">
                            <div>{match.homeTeam}</div>
                          </Col>
                          <Col className="risultato" md="2" xs="2">
                            <div>{this.getDateOrResult(match)}</div>
                          </Col>
                          <Col className="team-statistiche" md="5" xs="5">
                            <div>{match.guestTeam}</div>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-center rowGoals">
                          <Col md="5" xs="5">
                            <div>{this.state.homeGoals}</div>
                          </Col>
                          <Col md="2" xs="2">
                            <div>{this.getMatchDetail(match)}</div>
                          </Col>
                          <Col md="5" xs="5">
                            <div>{this.state.guestGoals}</div>
                          </Col>
                        </Row>
                      </Container>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-md-center">

              <Col md="4" sm="12">
                <Card style={{ opacity: 0.9 }}>
                  <Card.Body>
                    <Card.Text style={{ textAlign: "center" }}>
                      <div style={{ marginTop: "10px" }}>
                        {this.state.onShotValue}
                        {this.state.outShotValue}
                        {this.state.cornerValue}
                        {this.state.yellowValue}
                        {this.state.redValue}
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      );
    }
  }
}

export default MatchDetail;
