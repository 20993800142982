import React from 'react'
import {useLocation} from "react-router-dom";
import MatchDetail from '../MatchDetail';

function Match(props) {
    let data = useLocation();
    let matchId = data.state.matchId;
    return (
        <>
            <MatchDetail matchId={matchId}/>
        </>
    )
}

Match.propTypes = {

}

export default Match

