import React from "react";
import axios from "../../api";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Loading from "../Loading";
import Moment from "moment";

const schema = yup.object().shape({
  date: yup.date().required("Campo data obbligatorio"),
  matchType: yup.string().max(50).required("Campo tipo di match obbligatorio"),
});

class MatchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      match: [],
      allTeam: [],
      allGroups: [],
      group: [],
    };
  }

  componentDidMount() {
    if (this.props.id) {
      this.getMatch(this.props.id);
    } else {
      this.getAllTeam();
    }
  }

  getAllTeam() {
    axios
      .get("team/all")
      .then((response) => {
        this.setState({
          error: "",
          allTeam: response.data,
        });
        this.getAllGroups();
      })
      .catch((error) => {
        this.setState({
          error: "",
          loading: false,
        });
      });
  }

  getAllGroups() {
    axios
      .get("group/all")
      .then((response) => {
        this.setState({
          error: "",
          allGroups: response.data,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error: "",
          loading: false,
        });
      });
  }

  getMatch(id) {
    this.setState({
      loading: true,
    });
    axios
      .get("/match?id=" + id)
      .then((response) => {
        let match = response.data;
        axios
          .get("/match/statistics?id=" + id)
          .then((response2) => {
            this.setState({
              group: response2.data.group,
            });
          })
          .catch((error) => {
            this.setState({
              error: "",
              loading: false,
            });
          });
        this.setState({
          error: "",
          match: match,
        });
        this.getAllTeam();
      })
      .catch((error) => {
        this.setState({
          error: "Qualcosa è andato storto :) Contattaci se hai problemi",
          loading: false,
        });
      });
  }

  saveFunction(values) {
    this.setState({
      loading: true,
      error: "",
    });
    if (
      (values.matchType === "Gruppo" && !values.group) ||
      values.group === "Seleziona un gruppo"
    ) {
      this.setState({
        loading: false,
        error: "Seleziona un gruppo",
      });
    } else {
      this.state.allTeam.forEach((team) => {
        if (
          values.homeTeam &&
          team.id.toString() === values.homeTeam.toString()
        ) {
          values.homeTeam = team;
        }
        if (
          values.guestTeam &&
          team.id.toString() === values.guestTeam.toString()
        ) {
          values.guestTeam = team;
        }
      });
      if (values.group) {
        this.state.allGroups.forEach((group) => {
          if (group.id.toString() === values.group.toString()) {
            values.group = group;
          }
        });
      }
      axios
        .post("/match", values)
        .then((response) => {
          this.setState({
            error: "",
          });
          window.location = "/partite";
        })
        .catch((error) => {
          this.setState({
            error: "Qualcosa è andato storto :) Contattaci se hai problemi",
            loading: false,
          });
        });
    }
  }

  getButtons() {
    if (this.state.error) {
      return (
        <>
          <div className="error-message">{this.state.error}</div>
          <Button variant="primary" type="submit">
            Salva
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button variant="primary" type="submit">
            Salva
          </Button>
        </>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      const match = this.state.match;
      const groupResult = this.state.group;
      return (
        <Formik
          validationSchema={schema}
          onSubmit={(values, actions) => {
            this.setState({ isLoading: true });
            actions.setSubmitting(false);
            this.saveFunction(values);
          }}
          initialValues={{
            id: match.id ? match.id : null,
            date: match.date
              ? Moment(match.date).subtract(2, "h").format("YYYY-MM-DDTHH:mm")
              : "",
            homeTeam: match.homeTeam ? match.homeTeam.id : null,
            guestTeam: match.guestTeam ? match.guestTeam.id : null,
            matchType: match.matchType ? match.matchType : "Gruppo",
            group: groupResult && groupResult.id ? groupResult.id : null,
            status: "Programmata",
            video: match.video ? match.video : ""
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="form-style">
              <Form.Group controlId="validationFormikdate">
                <Form.Label>Data di inizio</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="datetime-local"
                    placeholder="Data di inizio"
                    aria-describedby="inputGroupPrepend"
                    name="date"
                    value={values.date}
                    onChange={handleChange}
                    isInvalid={!!errors.date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.date}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationFormikhomeTeam">
                <Form.Label>Squadra in casa</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    as="select"
                    placeholder="Squadra in casa"
                    aria-describedby="inputGroupPrepend"
                    name="homeTeam"
                    value={values.homeTeam}
                    onChange={handleChange}
                    isInvalid={!!errors.homeTeam}
                  >
                    <option value={null}>Seleziona una squadra</option>
                    {this.state.allTeam.map(function (team) {
                      return (
                        <option key={team.id} value={team.id}>
                          {team.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.homeTeam}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationFormikguestTeam">
                <Form.Label>Squadra in trasferta</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    as="select"
                    placeholder="Squadra in trasferta"
                    aria-describedby="inputGroupPrepend"
                    name="guestTeam"
                    value={values.guestTeam}
                    onChange={handleChange}
                    isInvalid={!!errors.guestTeam}
                  >
                    <option value={null}>Seleziona una squadra</option>
                    {this.state.allTeam.map(function (team) {
                      return (
                        <option key={team.id} value={team.id}>
                          {team.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.guestTeam}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationFormikmatchType">
                <Form.Label>Tipo di match</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    as="select"
                    aria-describedby="inputGroupPrepend"
                    defaultValue="Gruppo"
                    name="matchType"
                    value={values.matchType}
                    onChange={handleChange}
                    isInvalid={!!errors.matchType}
                  >
                    <option value="Gruppo">Gruppo</option>
                    <option value="Semifinale_Rovare_Cup">
                      Semifinale Rovarè Cup
                    </option>
                    <option value="Finale_Rovare_Cup">Finale Rovarè Cup</option>
                    <option value="Semifinale_Rovare_Cup">
                      Semifinale Rovarè Cup
                    </option>
                    <option value="Semifinale_Euro_Cup">
                      Semifinale Euro Cup
                    </option>
                    <option value="Finale_Euro_Cup">Finale Euro Cup</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.matchType}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group
                controlId="validationFormikgroup"
                hidden={values.matchType !== "Gruppo"}
              >
                <Form.Label>Gruppo</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    as="select"
                    aria-describedby="inputGroupPrepend"
                    name="group"
                    value={values.group}
                    onChange={handleChange}
                    isInvalid={!!errors.group}
                  >
                    <option value="Seleziona un gruppo">
                      Seleziona un gruppo
                    </option>
                    {this.state.allGroups.map(function (group) {
                      return (
                        <option key={group.id} value={group.id}>
                          {group.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.group}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationFormikVideo">
                <Form.Label>Url video youtube</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="string"
                    placeholder="URL video youtube"
                    aria-describedby="inputGroupPrepend"
                    name="video"
                    value={values.video}
                    onChange={handleChange}
                    isInvalid={!!errors.video}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.video}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              {this.getButtons()}
            </Form>
          )}
        </Formik>
      );
    }
  }
}

export default MatchForm;
