import React from "react";
import "../../App.css";
import Videos from "../Videos"

function Highlights() {
  return (
    <>
      <Videos />
    </>
  );
}

export default Highlights;
