import React from "react";
import Bomber from "../Classifica/Bomber";

function ClassificaBomber() {
  return (
    <>
      <Bomber title="BOMBER (*Eurocup non conteggiata)" />
    </>
  );
}

export default ClassificaBomber;
