import React from "react";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import API from "../../api";
import Loading from "../Loading";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CardTitle from "../Card/CardTitle";

class Bomber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      players: [],
      mapGroupDay: new Map(),
      loading: false,
      tableData: [],
    };
  }

  componentDidMount() {
    this.getPLayers();
  }

  getPLayers() {
    this.setState({
      loading: true,
    });
    API.get("capocannoniere")
      .then((response) => {
        this.setState({
          players: response.data,
        });
        this.setTable();
      })
      .catch((error) => {
        this.setState({
          error: "Error retrieving data",
          loading: false,
        });
      });
  }

  setTable() {
    const dataTabel = [];
    this.state.players.forEach((player) => {
      const row = [];
      row.push(player[0] + " " + player[1]);
      row.push(player[2].name);
      row.push(player[3]);
      dataTabel.push(row);
    });
    this.setState({
      tableData: dataTabel,
      loading: false,
    });
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      if (this.state.players && this.state.players.length > 0) {
        return (
          <div>
            <Container>
            <CardTitle text={this.props.title} />
            </Container>
            <Container fluid>
              <Row className="justify-content-md-center">
                <Col md="5">
                  <TableContainer component={Paper}>
                    <Table size="large" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ textAlign: "center" }}>
                            Giocatore
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Squadra
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <Image className="icon2" src="images/gol.png" />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.tableData.map((row) => (
                          <TableRow key={row[0]}>
                            <TableCell style={{ textAlign: "center" }}>
                              {row[0]}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {row[1]}
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>
                              {row[2]}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Col>
              </Row>
            </Container>
          </div>
        );
      } else {
        return (
          <div>
            <Container>
            <CardTitle text={this.props.title} />
            </Container>
            <Container fluid>
              <Row className="justify-content-md-center">
                <Col md="10">
                  <Card>
                    <Card.Body>
                      <Card.Header className="card-header-empty">
                        Nessun gol disponibile
                      </Card.Header>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }
    }
  }
}

export default Bomber;
