import React from 'react'
import { Card, Row, Container, Col } from "react-bootstrap";
import ManagerForm from '../Forms/ManagerForm';
import { useLocation } from "react-router-dom";

function Player() {
  let query = useQuery();
  let id = query.get("id");
    return (
        <>
      <Container>
        <div className="page-title">
          <h2>Dirigente</h2>
        </div>
        <Row className="justify-content-md-center statistic">
          <Col md="8">
            <Card>
              <Card.Body>
                <Card.Text className="justify-content-md-center statistic">
                  <ManagerForm id={id}/>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
    )
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default Player
