import axios from "axios";
import LoginService from "./service/LoginService"

const loginService = LoginService()

// const axios = require('axios');

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
const customAxios = axios.create({
  baseURL: `https://api.rovarecup.com/`,
  timeout: 50000,
});

// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.accessToken) {
    request.headers.Authorization = "Bearer " + user.accessToken;
  }
  return request;
};

const responseHandler = (response) => {
  if (response.status === 401 || response.status === 403) {
    loginService.logout()
    window.location = "/login";
  }
  return response;
};

const errorHandler = (error) => {
  if (error.response.status === 401 || error.response.status === 403) {
    loginService.logout()
    window.location = "/login";
  }
  return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;
