import React from "react";
import axios from "../../api";
import { Form, Button, InputGroup, Col, Container, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Loading from "../Loading";
import Girone from "../Classifica/Girone";

const schema = yup.object().shape({
  name: yup.string().max(255).required("Campo nome obbligatorio"),
});

class GroupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      group: "",
      allTeam: [],
      optionValue: [],
      allGroups: [],
    };
  }

  componentDidMount() {
    if (this.props.id) {
      this.getGroup(this.props.id);
    }
  }

  getGroup(id) {
    this.setState({
      loading: true,
    });
    axios
      .get("/group?id=" + id)
      .then((response) => {
        this.setState({
          error: "",
          group: response.data,
        });
        this.getAllGroups();
      })
      .catch((error) => {
        this.setState({
          error: "Qualcosa è andato storto :) Contattaci se hai problemi",
          loading: false,
        });
      });
  }

  getAllGroups() {
    this.setState({
      loading: true,
    });
    axios
      .get("/group/all")
      .then((response) => {
        this.setState({
          error: "",
          allGroups: response.data,
        });
        this.getAllTeam();
      })
      .catch((error) => {
        this.setState({
          error: "Qualcosa è andato storto :) Contattaci se hai problemi",
          loading: false,
        });
      });
  }

  getAllTeam() {
    this.setState({
      loading: true,
    });
    axios
      .get("team/all")
      .then((response) => {
        let validTeams = [];
        const groups = this.state.allGroups;
        let rankingsId = [];
        groups.forEach((group) => {
          group.rankings.forEach((ranking) => {
            rankingsId.push(ranking.id);
          });
        });

        response.data.forEach((team) => {
          if (
            rankingsId.length === 0 ||
            !team.ranking ||
            !rankingsId.includes(team.ranking.id)
          ) {
            validTeams.push(team);
          }
        });

        this.setState({
          error: "",
          allTeam: validTeams,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error: "",
          loading: false,
        });
      });
  }

  saveGroup(values) {
    this.setState({
      loading: true,
    });
    axios
      .post("/group", values)
      .then((response) => {
        this.setState({
          error: "",
        });
        window.location = "/gironeAdmin";
      })
      .catch((error) => {
        this.setState({
          error: "Qualcosa è andato storto :) ",
          loading: false,
        });
      });
  }

  saveRanking(values) {
    this.setState({
      loading: true,
    });
    if (!values.team || values.team === "Seleziona una squadra") {
      this.setState({
        loading: false,
        error: "Seleziona una squadra",
      });
    } else {
      this.state.allTeam.forEach((team) => {
        if (team.id.toString() === values.team.toString()) {
          values.team = team;
        }
      });
      axios
        .post("/ranking", values)
        .then((response) => {
          this.setState({
            error: "",
          });
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            error: "Qualcosa è andato storto :) ",
            loading: false,
          });
        });
    }
  }

  getButtons() {
    if (this.state.error) {
      return (
        <>
          <div className="error-message">{this.state.error}</div>
          <Button variant="primary" type="submit">
            Salva
          </Button>{" "}
        </>
      );
    } else {
      return (
        <>
          <Button variant="primary" type="submit">
            Salva
          </Button>{" "}
        </>
      );
    }
  }

  getFormGroup(group) {
    return (
      <Formik
        validationSchema={schema}
        onSubmit={(values, actions) => {
          this.setState({ isLoading: true });
          actions.setSubmitting(false);
          this.saveGroup(values);
        }}
        initialValues={{
          id: group.id,
          name: group.name,
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form noValidate onSubmit={handleSubmit} className="form-style">
            <Form.Group controlId="validationFormikName">
              <Form.Label>Nome del gruppo</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  type="string"
                  placeholder="Nome"
                  aria-describedby="inputGroupPrepend"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            {this.getButtons()}
          </Form>
        )}
      </Formik>
    );
  }

  getRankingForm(group, teams) {
    if (!this.state.loading && teams.length > 0) {
      return (
        <Formik
          onSubmit={(values, actions) => {
            this.setState({ isLoading: true });
            actions.setSubmitting(false);
            this.saveRanking(values);
          }}
          initialValues={{
            group: group,
            team: "Seleziona una squadra",
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="form-style">
              <Form.Group controlId="validationFormikteam">
                <InputGroup hasValidation>
                  <Form.Control
                    as="select"
                    aria-describedby="inputGroupPrepend"
                    name="team"
                    value={values.team}
                    onChange={handleChange}
                    isInvalid={!!errors.team}
                  >
                    <option value="Seleziona una squadra">
                      Seleziona una squadra
                    </option>
                    {teams.map(function (team) {
                      return (
                        <option key={team.id} value={team.id}>
                          {team.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.team}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              {this.getButtons()}
            </Form>
          )}
        </Formik>
      );
    }
  }

  render() {
    const group = this.state.group;
    const teams = this.state.allTeam;
    if (this.state.loading) {
      return <Loading />;
    } else {
      if (group) {
        return (
          <div>
            {this.getFormGroup(group)}
            <Container fluid>
              <Row className="justify-content-md-center">
                <Col md="12">{this.getRankingForm(group, teams)}</Col>
                <Col md="10">
                  <Girone group={group} groupId={group.id} />
                </Col>
              </Row>
            </Container>
          </div>
        );
      } else {
        return this.getFormGroup(group);
      }
    }
  }
}

export default GroupForm;
