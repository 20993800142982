import React from "react";
import API from "../../api";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Loading from "../Loading";
import Moment from "moment";

const schema = yup.object().shape({
  name: yup.string().max(50).required("Campo nome obbligatorio"),
  surname: yup.string().max(50).required("Campo cognome obbligatorio"),
  birthOfDay: yup.date().required("Campo data obbligatorio"),
  town: yup.string().max(100).required("Campo città di nascita obbligatorio"),
  codiceFiscale: yup
    .string()
    .min(16, "Il codice fiscale deve avere 16 caratteri")
    .max(16, "Il codice fiscale deve avere 16 caratteri")
    .required("Campo codice fiscale obbligatorio"),
  document: yup.string().required("Campo tipo di documento obbligatorio"),
  documentNumber: yup.string().max(20).required("Campo documento obbligatorio"),
});

class PlayerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      player: [],
    };
  }

  componentDidMount() {
    if (this.props.id) {
      this.getPlayer(this.props.id);
    }
  }

  getPlayer(id) {
    this.setState({
      loading: true,
    });
    API.get("/player?id=" + id)
      .then((response) => {
        this.setState({
          error: "",
          loading: false,
          player: response.data,
        });
      })
      .catch((error) => {
        this.setState({
          error: "Qualcosa è andato storto :) Contattaci se hai problemi",
          loading: false,
        });
      });
  }

  saveFunction(values) {
    this.setState({
      loading: true,
    });
    API.post("/customer/player", values)
      .then((response) => {
        this.setState({
          error: "",
        });
        window.location = "/team";
      })
      .catch((error) => {
        this.setState({
          error: "Qualcosa è andato storto :) Contattaci se hai problemi",
          loading: false,
        });
      });
  }

  getButtons() {
    if (this.state.error) {
      return (
        <>
          <div className="error-message">{this.state.error}</div>
          <Button variant="primary" type="submit">
            Salva
          </Button>
        </>
      );
    } else {
      return (
        <Button variant="primary" type="submit">
          Salva
        </Button>
      );
    }
  }

  render() {
    const player = this.state.player;
    if (this.state.loading) {
      return <Loading />;
    } else {
      return (
        <Formik
          validationSchema={schema}
          onSubmit={(values, actions) => {
            this.setState({ isLoading: true });
            actions.setSubmitting(false);
            this.saveFunction(values);
          }}
          initialValues={{
            id: player.id,
            name: player.name,
            surname: player.surname,
            birthOfDay: player.birthOfDay
              ? Moment(player.birthOfDay).format("YYYY-MM-DD")
              : "",
            town: player.town,
            codiceFiscale: player.codiceFiscale,
            document: player.document ? player.document : "C.I.",
            documentNumber: player.documentNumber,
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="form-style">
              <Form.Group controlId="validationFormikName">
                <Form.Label>Nome</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="string"
                    placeholder="Nome"
                    aria-describedby="inputGroupPrepend"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationFormikSurname">
                <Form.Label>Cognome</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="string"
                    placeholder="Cognome"
                    aria-describedby="inputGroupPrepend"
                    name="surname"
                    value={values.surname}
                    onChange={handleChange}
                    isInvalid={!!errors.surname}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.surname}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationFormikbirthOfDay">
                <Form.Label>Data di nascita</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="date"
                    placeholder="Data di nascita"
                    aria-describedby="inputGroupPrepend"
                    name="birthOfDay"
                    value={values.birthOfDay}
                    onChange={handleChange}
                    isInvalid={!!errors.birthOfDay}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.birthOfDay}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationFormiktown">
                <Form.Label>Città di nascita</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="string"
                    placeholder="Città di nascita"
                    aria-describedby="inputGroupPrepend"
                    name="town"
                    value={values.town}
                    onChange={handleChange}
                    isInvalid={!!errors.town}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.town}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationFormikcodiceFiscale">
                <Form.Label>Codice fiscale</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="string"
                    placeholder="Codice fiscale"
                    aria-describedby="inputGroupPrepend"
                    name="codiceFiscale"
                    value={values.codiceFiscale}
                    onChange={handleChange}
                    isInvalid={!!errors.codiceFiscale}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.codiceFiscale}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationFormikDocument">
                <Form.Label>Documento</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    as="select"
                    placeholder="Tipo di documento"
                    aria-describedby="inputGroupPrepend"
                    name="document"
                    value={values.document}
                    onChange={handleChange}
                    isInvalid={!!errors.document}
                  >
                    <option>C.I.</option>
                    <option>Patente</option>
                    <option>Passaporto</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.document}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationFormikdocumentNumber">
                <Form.Label>Numero documento</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="string"
                    placeholder="Documento"
                    aria-describedby="inputGroupPrepend"
                    name="documentNumber"
                    value={values.documentNumber}
                    onChange={handleChange}
                    isInvalid={!!errors.documentNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.documentNumber}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              {this.getButtons()}
            </Form>
          )}
        </Formik>
      );
    }
  }
}

export default PlayerForm;
