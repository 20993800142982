import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Products from "./components/pages/Products";
import Services from "./components/pages/Services";
import SignUp from "./components/pages/SignUp";
import Footer from "./components/Footer";
import Contact from "./components/pages/Contact";
import Calendar from "./components/pages/Calendar";
import Classifiche from "./components/pages/Classifiche";
import Highlights from "./components/pages/Highlights";
import Subscribe from "./components/pages/Subscribe";
import "bootstrap/dist/css/bootstrap.min.css";
import Match from "./components/pages/Match";
import Login from "./components/pages/Login";
import Registration from "./components/pages/Registration";
import Player from "./components/pages/Player";
import Manager from "./components/pages/Manager";
import Team from "./components/pages/Team";
import history from "./helper/history";
import { ProtectedRoute } from "./helper/ProtectedRoute";
import { ProtectedRouteAdmin } from "./helper/ProtectedRouteAdmin";
import RecuperaPassword from "./components/pages/RecuperaPassword";
import ResetPassword from "./components/pages/ResetPassword";
import NavbarHelper from "./helper/NavbarHelper"
import GironeManager from "./components/pages/GironeManager";
import Group from "./components/pages/Group";
import MatchManager from "./components/pages/MatchManager";
import MatchAdminFormPage from "./components/pages/MatchAdminFormPage";
import TeamManager from "./components/pages/TeamManager";
import TeamManagerEdit from "./components/pages/TeamManagerEdit";
import MatchAdmin from "./components/pages/MatchAdmin";
import Moment from 'react-moment';
import moment from 'moment/min/moment-with-locales';
import 'moment-timezone';
import ClassificaBomber from "./components/pages/ClassificaBomber";
import ClassificaCartellini from "./components/pages/ClassificaCartellini";
import MatchDetailPage from "./components/pages/MatchLivePage";
import TeamDetailPage from "./components/TeamDetailPage";
import TeamsTable from "./components/Admin/TeamsTable";
import TeamDetail from "./components/pages/TeamDetails";
import ReactGA from 'react-ga';

const TRACKING_ID = "G-0MXECNXSGM"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

Moment.globalMoment = moment;
Moment.globalFormat = 'DD MMMM HH:mm';
Moment.globalLocale = 'IT';

Moment.globalFilter = (d) => {
  return d.charAt(0).toUpperCase() + d.slice(1);
};

history.listen((location) => {
  ReactGA.pageview(location.pathname + location.search);
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
    };
  }

  render() {
    return (
      <Router history={history}>
        <NavbarHelper />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/products" exact component={Products} />
          <Route path="/services" exact component={Services} />
          <Route path="/sign-up" exact component={SignUp} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/calendar" exact component={Calendar} />
          <Route path="/classifiche" exact component={Classifiche} />
          <Route path="/highlights" exact component={Highlights} />
          <Route path="/subscribe" exact component={Subscribe} />
          <Route path="/match" exact component={Match} />
          <Route path="/login" exact component={Login} />
          <Route path="/registrazione" exact component={Registration} />
          <Route path="/recupera-password" exact component={RecuperaPassword} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route path="/bomber" exact component={ClassificaBomber} />
          <Route path="/cartellini" exact component={ClassificaCartellini} />
          <Route path="/matchLive" exact component={MatchDetailPage} />
          <Route
            exact
            path="/partite"
            component={MatchManager}
          ></Route>
          <Route
            exact
            path="/teamDetail"
            component={TeamDetail}
          ></Route>
          <ProtectedRoute
            exact
            path="/player"
            component={Player}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path="/team"
            component={Team}
          ></ProtectedRoute>
          <ProtectedRoute
            exact
            path="/manager"
            component={Manager}
          ></ProtectedRoute>
          <ProtectedRouteAdmin
            exact
            path="/gironeAdmin"
            component={GironeManager}
          ></ProtectedRouteAdmin>
          <ProtectedRouteAdmin
            exact
            path="/teamsAdmin"
            component={TeamManager}
          ></ProtectedRouteAdmin>
          <ProtectedRouteAdmin
            exact
            path="/teamAdminEdit"
            component={TeamManagerEdit}
          ></ProtectedRouteAdmin>
          <ProtectedRouteAdmin
            exact
            path="/girone"
            component={Group}
          ></ProtectedRouteAdmin>
          <ProtectedRouteAdmin
            exact
            path="/partiteAdminForm"
            component={MatchAdminFormPage}
          ></ProtectedRouteAdmin>
          <ProtectedRouteAdmin
            exact
            path="/matchAdmin"
            component={MatchAdmin}
          ></ProtectedRouteAdmin>
        </Switch>
        <Footer />
      </Router>
    );
  }
}

export default App;
