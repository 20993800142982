import React from "react";
import axios from "../../api";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Loading from "../Loading";

const schema = yup.object().shape({
  homeGol: yup.number().required("Campo gol in casa obbligatorio"),
  guestGol: yup.number().required("Campo gol in trasferta obbligatorio"),
});

class RigoriForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  saveFunction(values) {
    this.setState({
      loading: true,
    });
    axios
      .put("/match/completedMatch?matchStatus=Finita", this.props.match)
      .then(() => {
        axios
          .put(
            "/ranking/match/penalty?matchId=" +
              this.props.match.id +
              "&homeGol=" +
              values.homeGol +
              "&guestGol=" +
              values.guestGol
          )
          .then(() => {
            localStorage.removeItem(this.props.match.id + "secondHalf");
            localStorage.removeItem(this.props.match.id + "start");
            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        this.setState({
          error: "Qualcosa è andato storto :) Contattaci se hai problemi",
          loading: false,
        });
      });
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      return (
        <>
          <Formik
            validationSchema={schema}
            onSubmit={(values, actions) => {
              this.setState({ isLoading: true });
              actions.setSubmitting(false);
              this.saveFunction(values);
            }}
            initialValues={{
              homeGol: null,
              guestGol: null,
              match: this.props.match,
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit} className="form-style">
                <Form.Group controlId="validationFormikhomeGol">
                  <Form.Label>Rigori squadra in casa</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="number"
                      placeholder="Rigori squadra in casa"
                      aria-describedby="inputGroupPrepend"
                      name="homeGol"
                      value={values.homeGol}
                      onChange={handleChange}
                      isInvalid={!!errors.homeGol}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.homeGol}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="validationFormikguestGol">
                  <Form.Label>Rigori squadra ospite</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type="number"
                      placeholder="Rigori squadra ospite"
                      aria-describedby="inputGroupPrepend"
                      name="guestGol"
                      value={values.guestGol}
                      onChange={handleChange}
                      isInvalid={!!errors.guestGol}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.guestGol}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <div className="error-message">{this.state.error}</div>
                <Button variant="primary" type="submit">
                  Salva
                </Button>{" "}
              </Form>
            )}
          </Formik>
        </>
      );
    }
  }
}

export default RigoriForm;
