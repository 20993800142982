import React from "react";
import "../../App.css";
import { Container, Card, Row, Col } from "react-bootstrap";
import GoogleMap from "google-map-react";
import CardTitle from "../Card/CardTitle";

const AnyReactComponent = ({ text }) => (
  <i className="fas fa-map-marker-alt marker"></i>
);

class Contact extends React.Component {
  render() {
    const setting = {
      center: {
        lat: 45.679242,
        lng: 12.406569,
      },
      zoom: 14,
    };
    return (
      <>
        <div>
          <Container>
            <CardTitle text="CONTATTI" />
            <Row className="justify-content-md-center">
              <Col md="10">
                <Card>
                  <Card.Body className="information-card">
                    <Container>
                      <Row className="justify-content-md-center">
                        <Col md="6">
                          <Container>
                            <Row className="justify-content-md-center">
                              <Col md="12" style={{ paddingTop: "20px" }}>
                                <h5>
                                  La nostra sede e il nostro campo da gioco
                                </h5>
                                <span>
                                  <i className="fas fa-map-marker-alt"></i>
                                  Via Canareggio 37 Rovarè, Veneto, Italy
                                </span>
                                <hr />
                              </Col>
                              <Col md="12">
                                <h5>I nostri contatti</h5>
                                <div>
                                  <i className="fas fa-phone"> </i>
                                  Marco 340 3705754
                                </div>
                                <div>
                                  <i className="fas fa-phone"> </i>
                                  Enrico 349 7114426
                                </div>
                                <div>
                                  <i className="fas fa-at"> </i>
                                  rovarecup@gmail.com
                                </div>
                                <hr />
                              </Col>
                              <Col md="12" style={{ paddingBottom: "20px" }}>
                                <h5>I nostri social</h5>
                                <span>
                                  <a href="https://www.facebook.com/Rovarecupofficial/">
                                    <i className="fab fa-facebook fa-2x facebook"></i>
                                  </a>
                                  <a href="https://www.instagram.com/officialrovarecup/">
                                    <i className="fab fa-instagram fa-2x"></i>
                                  </a>
                                  <a href="https://www.youtube.com/channel/UC4kL40EfJsdADJEpzC6E_OA?view_as=subscriber">
                                    <i className="fab fa-youtube fa-2x youtube"></i>
                                  </a>
                                </span>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                        <Col>
                          <div style={{ height: "300px", width: "100%" }}>
                            <GoogleMap
                              apiKey="AIzaSyDnSlVt6vZ3a3MJg1bAn_Sz-r9pmWrA-Kw"
                              center={setting.center}
                              zoom={setting.zoom}
                            >
                              <AnyReactComponent
                                lat={45.679242}
                                lng={12.406569}
                                text="Eccoci"
                              />
                            </GoogleMap>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                  <Card.Text></Card.Text>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Contact;
