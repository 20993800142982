import {Component} from 'react';

class BlinkText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showText: false,
    };
    setInterval(() => {
      this.setState((previousState) => {
        return {showText: !previousState.showText};
      });
    }, 800);
  }

  render() {
    const {textData} = this.props;
    const {showText} = this.state;
    if(showText) {
      return textData
    } else {
      return ' '
    }
  }
}

export default BlinkText;
