import React from "react";
import { Card, Row, Container, Col } from "react-bootstrap";
import LoginForm from "../Login/LoginForm";
import CardTitle from "../Card/CardTitle";

function Login() {
  return (
    <>
      <Container>
      <CardTitle text='LOGIN' />
        <Row className="justify-content-md-center statistic">
          <Col md="8">
            <Card>
              <Card.Body>
                <Card.Text className="justify-content-md-center statistic">
                  <LoginForm />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
