import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Loading from "../Loading";
import axios from "../../api";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

class PlayersTeamDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tableHead: ["Giocatore", "", ""],
      tableTitle: [],
      tableData: [],
      players: [],
      playersData: [],
    };
  }

  render() {
    const tableData = this.props.players;
    if (tableData.length === 0) {
      return (
        <div>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <div>
                  Nessun giocatore
                  <div>
                    <Link
                      to={{
                        pathname: "/player",
                      }}
                    ></Link>
                  </div>
                </div>
              </TableHead>
            </Table>
          </TableContainer>
        </div>
      );
    } else {
      if (this.state.loading) {
        return <Loading />;
      } else {
        return (
          <div>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "center", width: "90%" }}
                    ></TableCell>
                    <TableCell style={{ textAlign: "center" }}></TableCell>
                    <TableCell style={{ textAlign: "center" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow
                      key={row[0]}
                    >
                      <TableCell style={{ textAlign: "left", width: "90%" }}>
                        {index + 1} - {row[0]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      }
    }
  }
}

export default PlayersTeamDetail;
