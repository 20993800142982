import React from 'react'
import {useLocation} from "react-router-dom";
import MatchDetailAdmin from '../Admin/MatchDetailAdmin';

function MatchAdmin(props) {
    let data = useLocation();
    let matchId = data.state.matchId;
    return (
        <>
            <MatchDetailAdmin matchId={matchId}/>
        </>
    )
}

MatchAdmin.propTypes = {

}

export default MatchAdmin

