import React from "react";
import "../../App.css";
import Classifica from "../Classifica/Classifica"


function Classifiche() {
  return (
    <>
    <Classifica title='GIRONI'/>
    </>
  );
}

export default Classifiche