/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import "./CardDefault.css";

function CardDefault(props) {
  return (
    <>
      <figure className="cards_item_pic-wrap" data-category={props.label} />
      <li className="cards_item">
        <div className="cards_item_link" to={props.path}>
          <div className="cards_info">
            {props.text}
          </div>
        </div>
      </li>
    </>
  );
}

export default CardDefault;
