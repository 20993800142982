import React from "react";
import API from "../../api";
import { Form, Button, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import Loading from "../Loading";

const schema = yup.object().shape({
  password: yup.string().required("Campo password obbligatorio"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Le password non corrispondono")
    .required("Campo conferma password obbligatorio"),
});

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      infoMessage: "",
      error: "",
    };
  }

  reset(values) {
    API.post(
      "api/auth/reset?token=" +
        this.props.token +
        `&password=` +
        values.password
    )
      .then((response) => {
        this.setState({
          error: "",
          infoMessage: "Nuova password impostata, vai alla login",
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          infoMessage: "",
          error:
            "Impossibile effettuare il reset della password. Controlla che il link che stai utilizzando è quello ricevuto via email oppure contattaci per risolvere il problema",
          loading: false,
        });
      });
  }

  getButtons() {
    if (this.state.error) {
      return (
        <>
          <div className="error-message">{this.state.error}</div>
          <Button variant="primary" type="submit">
            Reset password
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button variant="primary" type="submit">
            Reset password
          </Button>
          <div>{this.state.infoMessage}</div>
        </>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    } else {
      return (
        <Formik
          validationSchema={schema}
          onSubmit={(values, actions) => {
            this.setState({ isLoading: true });
            actions.setSubmitting(false);
            this.reset(values);
          }}
          initialValues={{
            password: "",
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="form-style">
              <Form.Group controlId="validationFormikUsername">
                <Form.Label>Nuova password</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    aria-describedby="inputGroupPrepend"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group controlId="validationFormikPasswordConfirmation">
                <Form.Label>Conferma nuova password</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="password"
                    placeholder="Conferma password"
                    aria-describedby="inputGroupPrepend"
                    name="passwordConfirmation"
                    value={values.passwordConfirmation}
                    onChange={handleChange}
                    isInvalid={!!errors.passwordConfirmation}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.passwordConfirmation}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              {this.getButtons()}
            </Form>
          )}
        </Formik>
      );
    }
  }
}

export default ResetPasswordForm;
